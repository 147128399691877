import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { solid: "", fluid: "", tag: "section", id: "company-playlist" } },
    [
      _c(
        "div",
        { class: _vm.gs.isMobile() ? "mx-auto text-center" : "mx-13" },
        [
          _c("img", {
            staticStyle: { "max-width": "180px", "max-height": "180px" },
            attrs: { src: require("../../../../assets/dxa-invest.png") },
          }),
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(
                "h1",
                {
                  staticClass: "company-name",
                  class: _vm.gs.isMobile() ? "mx-auto" : "",
                },
                [_vm._v(" " + _vm._s(_vm.company.Name) + " ")]
              ),
              !_vm.gs.isMobile() ? _c(VSpacer) : _vm._e(),
              _c(
                VBtn,
                {
                  class: _vm.gs.isMobile() ? "mx-auto" : "",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "text-transform": "uppercase" } },
                    [_vm._v(_vm._s(_vm.$t("go_back")))]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            VRow,
            { staticClass: "mx-10", attrs: { justify: "center" } },
            _vm._l(_vm.videos, function (video, i) {
              return _c(
                VCol,
                {
                  key: i,
                  staticClass: "my-0 py-0",
                  attrs: { cols: "12", sm: "6", md: "4", lg: "4" },
                },
                [
                  _c("iframe", {
                    staticStyle: {
                      "margin-bottom": "10px",
                      "margin-top": "10px",
                      "max-height": "350px",
                    },
                    attrs: {
                      src:
                        "https://player.vimeo.com/video/" +
                        video +
                        "?portrait=0&byline=0&title=0",
                      width: "100%",
                      height: _vm.gs.isMobile() ? "250" : "350",
                      frameborder: "0",
                      allow: "autoplay; fullscreen",
                      allowfullscreen: "",
                    },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }